import React, { useEffect, useRef } from "react";
import Swiper from "swiper";

import { buildImageObj, buildSrcSet, cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import "../styles/swiper.css";
import styles from "./poster-slider.module.css";

const config = {
  slidesPerView: "auto",
  centeredSlides: true,
  spaceBetween: 14,
  allowTouchMove: false,
  breakpoints: {
    816: {
      spaceBetween: 28
    },
    1700: {
      spaceBetween: 56
    }
  },
  preloadImages: false,
  watchSlidesVisibility: true,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
    loadOnTransitionStart: true
  }
};

const PosterSlider = props => {
  const { posters, onSlideChange, onSideClick, initialSlide } = props;

  const slider = useRef(null);

  useEffect(() => {
    config.initialSlide = initialSlide;
    slider.current = new Swiper(".swiper-container", config);

    slider.current.on("slideChange", () => {
      onSlideChange(slider.current.activeIndex);
    });

    props.sliderRef(slider);
  }, []);

  return (
    <div className={cn("swiper-container", styles.root)}>
      <div className="swiper-wrapper">
        {posters.map((poster, index) => (
          <PosterSlide poster={poster} key={poster.slug.current} />
        ))}
      </div>
      {props.hasPrev && (
        <div className={styles.prevContainer} onClick={() => onSideClick("prev")}></div>
      )}
      {props.hasNext && (
        <div className={styles.nextContainer} onClick={() => onSideClick("next")}></div>
      )}
    </div>
  );
};

const sizes = [{ width: 400 }, { width: 600 }, { width: 800 }, { width: 1200 }];

const PosterSlide = ({ poster }) => {
  const imageUrl = imageUrlFor(buildImageObj(poster.mainImage));

  return (
    <div className={cn("swiper-slide", styles.slide)}>
      <div className={styles.imageContainer}>
        <figure className={styles.image}>
          <img
            data-srcset={buildSrcSet(imageUrl, sizes)}
            data-sizes="(max-width: 70em) 70vw, 40vw"
            alt={poster.mainImage.alt}
            className="swiper-lazy"
          />
        </figure>
      </div>
    </div>
  );
};

export default PosterSlider;
