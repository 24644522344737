import React, { useRef } from "react";
import { prepPosterMeta } from "../lib/meta";

import PosterSlider from "./poster-slider";
import { IntroSection } from "./IntroSection";
import SEO from "../components/seo";

import { PageActionNav } from "./page-nav";

import styles from "./poster-gallery.module.css";

function PosterGallery(props) {
  const { posters, initialSlide } = props;
  const [selectedPoster, setPoster] = React.useState(posters[initialSlide]);

  const handleSlideChange = React.useCallback(index => {
    const poster = posters[index];
    const url = `/poster/${poster.slug.current}`;

    setPoster(poster);

    if (history) {
      history.replaceState(null, poster.title, url);
    }
  });

  const meta = selectedPoster
    ? prepPosterMeta({
        members: selectedPoster.members,
        client: selectedPoster.client,
        year: selectedPoster.year,
        design: selectedPoster.design
      })
    : undefined;

  const slider = useRef();

  const handleSliderNav = dir => {
    switch (dir) {
      case "prev":
        slider.current.slidePrev();
        break;
      case "next":
        slider.current.slideNext();
        break;
    }
  };

  const hasNext = slider.current ? !slider.current.isEnd : initialSlide < posters.length - 1;
  const hasPrev = slider.current ? !slider.current.isBeginning : initialSlide > 0;

  const image = selectedPoster ? selectedPoster.mainImage : undefined;
  const title = selectedPoster ? selectedPoster.title : "Poster";

  return (
    <>
      <SEO title={title} slug="poster" image={image} />

      <article>
        <PosterSlider
          sliderRef={ref => (slider.current = ref.current)}
          posters={posters}
          initialSlide={initialSlide}
          onSlideChange={handleSlideChange}
          onSideClick={handleSliderNav}
          hasNext={hasNext}
          hasPrev={hasPrev}
        />

        <div className={styles.contentContainer}>
          {selectedPoster && <h1 className={styles.title}>{selectedPoster.title}</h1>}
          {meta && <IntroSection meta={meta} />}
        </div>
      </article>

      <PageActionNav onClick={handleSliderNav} hasNext={hasNext} hasPrev={hasPrev} />
    </>
  );
}

export default PosterGallery;
