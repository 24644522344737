import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import PosterGallery from "../components/poster-gallery";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query PostersTemplateQuery {
    page: sanityPostersPage(_id: { eq: "postersPage" }) {
      posters {
        id
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
        title
        client
        year
        design
        slug {
          current
        }
      }
    }
  }
`;

const PostersTemplate = props => {
  const { data, errors, posterId } = props;
  const posters = (data.page || {}).posters || [];

  const initialSlide = posters.findIndex(p => p.slug.current === posterId) || 0;
  const selectedPoster = posters[initialSlide] || {};

  const image = selectedPoster.mainImage || undefined;

  return (
    <Layout slug={"poster"}>
      {errors && <SEO title="GraphQL Error" />}
      {posters && <SEO title={selectedPoster.title || "Posters"} image={image} slug="poster" />}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {posters && <PosterGallery posters={posters} initialSlide={initialSlide} />}
    </Layout>
  );
};

export default PostersTemplate;
